import React, { useState, useEffect } from "react";
import Map from "../components/map";
import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { wktToGeoJSON } from "@terraformer/wkt";
import { makeTrackPath } from "../utils/slug";
import useTracks from "../utils/useTracks";
import Multiselect from 'multiselect-react-dropdown';


function sortByWeight(a, b) {
  if (a.weight < b.weight) {
    return -1;
  }
  if (a.weight > b.weight) {
    return 1;
  }
  return 0;
}

const MapPage = ({ data, location }) => {
  const [showFilters, setShowFilters] = useState(true)

  const tracks = data.allNodeTrack.nodes.filter(node => node.field_coordinates.geo_type === "LineString")
    .map((track) => ({
      id: track.id,
      relationships: track.relationships,
      coordinates: wktToGeoJSON(track.field_coordinates.value).coordinates.map(point => point.reverse()),
      title: track.title,
      activities: track.relationships.field_activity.map(a => (a.name)),
      levels: track.relationships.field_level.map(a => (a.name)),
      subjects: [...new Set(track.relationships.field_point.map(p => (p.relationships.field_subject.map(a => (a.name))[0])))],
      body: track.body?.value || "",
      path: makeTrackPath(track),
      image: track.relationships.field_image?.localFile || null,
      municipality: track.relationships.field_gmina ? track.relationships.field_gmina.name : null,
    }))

  const [filteredPoints, setFilteredPoints] = useState([])
  // const [filteredTracks, setFilteredTracks] = useState(tracks)

  const subjects = data.allTaxonomyTermSubject.nodes.map(a => a.name)
  const [subjectsFilters, setSubjectsFilters] = useState([])

  const activities = data.allTaxonomyTermActivity.nodes.map(a => a.name)
  const [activitiesFilters, setActivitiesFilters] = useState([])

  const levels = data.allTaxonomyTermLevel.nodes.map(a => a.name)
  const [levelsFilters, setLevelsFilters] = useState([])

  const municipalities = data.allTaxonomyTermGmina.nodes.map(a => a.name)
  const [municipalitiesFilters, setMunicipalitiesFilters] = useState([])

  const filteredTracks = useTracks(tracks, { activitiesFilters, levelsFilters, municipalitiesFilters, subjectsFilters })

  const filterPoints = () => {
    setFilteredPoints(
      filteredTracks.map(track => track.relationships.field_point).flat(1)
        .filter((v, i, a) => a.findIndex(f => f.id === v.id) === i)   // filter points by id uniqness
        .filter(node => node.field_coordinates.geo_type === "Point")  // display only those with 
        .map((point) => ({
          id: point.id,
          coordinates: wktToGeoJSON(point.field_coordinates.value).coordinates.reverse(),
          title: point.title,
          body: point.field_description,
          image: point.relationships.field_image?.localFile || null,
          tracks: point.relationships.node__track?.map(track => ({ title: track.title, path: makeTrackPath(track) })) || [],
        }))
    )
  }

  useEffect(() => {
    filterPoints()
  }, [filteredTracks])

  return (
    <DefaultLayout marginless={true} footerLess={true}>
      <SEO title="edumapa" />
      <div className="flex w-full h-full">
        <aside className={`absolute bg-blue-200 border border-2 border-blue-400 w-64 h-96 mt-4 p-2 flex flex-col rounded-l rounded-3xl z-10 ${showFilters ? "left-0" : "-left-64"}`}>
          <div
            className="absolute bg-blue-200 border border-2 border-blue-400 -right-5 top-4 w-5 h-12 rounded-r "
            onClick={() => setShowFilters(!showFilters)}
          >
            <div className="h-full w-full m-auto ">
              <span className="align-middle font-bold text-gray-600">{showFilters ? "<" : ">"}</span>
            </div>
          </div>
          <div className="overflow-y-scroll h-full">
            <div className="flex flex-col m-3 ">
              <span className="text-lg font-bold">Filtruj</span>
              <Multiselect
                className="bg-white rounded mb-2"
                placeholder="Aktywność"
                options={activities}
                onSelect={f => setActivitiesFilters([...f])}
                onRemove={f => setActivitiesFilters([...f])}
                isObject={false}
              />
              <Multiselect
                className="bg-white rounded mb-2"
                placeholder="Poziom"
                options={levels}
                onSelect={f => setLevelsFilters([...f])}
                onRemove={f => setLevelsFilters([...f])}
                isObject={false}
              />
              <Multiselect
                className="bg-white rounded mb-2"
                placeholder="Przedmiot"
                options={subjects}
                onSelect={f => setSubjectsFilters([...f])}
                onRemove={f => setSubjectsFilters([...f])}
                isObject={false}
              />
              <Multiselect
                className="bg-white rounded"
                placeholder="Gmina"
                options={municipalities}
                onSelect={f => setMunicipalitiesFilters([...f])}
                onRemove={f => setMunicipalitiesFilters([...f])}
                isObject={false}
              />
            </div>
          </div>
        </aside>
        <Map
          points={filteredPoints}
          tracks={filteredTracks}
          location={location}
          className="h-full w-full z-0 relative"
        />
      </div>
    </DefaultLayout>
  )
}
export default MapPage

export const query = graphql`
query {
    allTaxonomyTermGmina {
        nodes {
            name
        }
    }
    allTaxonomyTermLevel {
        nodes {
            name
            weight
        }
    }
    allTaxonomyTermActivity {
        nodes {
            name
            weight
        }
    }
    allTaxonomyTermSubject {
        nodes {
            name
            weight
        }
    }
    allNodeTrack {
        nodes {
            title
            id: drupal_internal__nid
            field_coordinates {
                value
                geo_type
            }
            body {
                value
            }
            relationships {
              field_gmina {
                name
              }
              field_activity {
                  name
              }
              field_level {
                  name
              }
              field_point {
                title
                id: drupal_internal__nid
                field_coordinates {
                    value
                    geo_type
                }
                field_description
                relationships {
                  field_subject {
                    name
                  }
                  field_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FIXED,
                          placeholder: BLURRED,
                          width: 150
                        )
                      }
                    }
                  }
                  node__track {
                    title
                    id: drupal_internal__nid
                  }
                }
              }
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED,
                      placeholder: BLURRED,
                      width: 150
                    )
                  }
                }
              }
            }
        }
    }
  }
`