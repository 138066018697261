import React, { useEffect, useState } from 'react'
import { MapContainer, useMapEvents, TileLayer, Popup, Marker, Polyline, ScaleControl, ZoomControl } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import piaseczenski from "../data/piaseczenski.json"
import randomColor from 'randomcolor';
import { getBounds } from "../utils/bounds"
import colorIcons from "../components/markers";
import ReactMarkdown from 'react-markdown'


function pushUrl(url) {
  if (typeof window !== `undefined` && typeof url !== `undefined`) {
    window.history.replaceState({}, '', url);
  }
  return
}

const MapHelper = ({points, tracks, location}) => {
  function moveHandler(e) {
      const center = e.target.getCenter();
      const zoom = e.target.getZoom();
      const url = "#" + center.lat.toFixed(5) + "/" + center.lng.toFixed(5) + "/" + String(zoom)
      pushUrl(url)
  }

  const map = useMapEvents({
    moveend: moveHandler,
    zoomend: moveHandler,
  })

  useEffect(() => {
    let hash_location = location.hash.split("#")
    hash_location = hash_location[hash_location.length - 1]
    if (hash_location !== "") {
      try {
        let [lat, lon, zoom] = hash_location.split("/").map((n) => Number(n)).map(n => {
          if (isNaN(n)) {
            throw "some field is not a number";
          }
          return n
        })
        map.setView([lat, lon], zoom);
      } catch (e) {
        console.log("failed to parse url location:", e)
      }
    } else {
      map.fitBounds(getBounds(tracks, points).geometry.coordinates);
    }
  }, [location]);

  return null
}

function Map(props) {

  if (typeof window !== 'undefined') {
    return (
        <MapContainer 
          className={props.className} 
          scrollWheelZoom={true}
          zoomControl={false}
        >
          {/* <MapHelper location={props.location} points={props.points} tracks={props.tracks}/> */}
          <MapHelper {...props}/>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ScaleControl position="bottomright" imperial={false}/>
          <ZoomControl position='topright' />
          <MarkerClusterGroup showCoverageOnHover={false}>
          {props.points.map((point) => (
            <Marker 
              position={point.coordinates} key={point.id}
              icon={colorIcons.blue}
            >
              <Popup
              >
                <div className="flex flex-col w-44" >
                  <span className="font-bold">
                    {point.title}
                  </span>
                  {/* <p dangerouslySetInnerHTML={{__html: point.body}} /> */}
                  <p>
                    <ReactMarkdown children={point.body}/>
                  </p>
                  {/* <p className="my-1">{point.body}</p> */}
                  {point.image && 
                    <GatsbyImage 
                      className="mt-2"
                      image={getImage(point.image)}
                      alt={point.title}
                    />
                  }
                  <span className="mt-1 font-semibold">scenariusze:</span>
                  {point.tracks.map(track => (
                  <Link key={track.path} className="mt-2" to={track.path}>{track.title}</Link> 
                  ))}
                </div>
              </Popup>
            </Marker>
          ))}
          </MarkerClusterGroup>

          <Polyline pathOptions={{color: "gray", dashArray: "10, 20", interactive: false}} positions={piaseczenski} key="piaseczenski" />

          {props.tracks.map((track) => (
            <Polyline 
              positions={track.coordinates}
              key={String(track.coordinates)} 
              weight="5"
              color={randomColor({
                seed: track.title,
                luminosity: "dark",
              })}
            >
              <Popup>
                <div className="flex flex-col" >
                <span className="font-bold">
                {track.title}
                </span>
                {track.image && 
                  <GatsbyImage 
                    className="mt-2"
                    image={getImage(track.image)}
                    alt={track.title}
                  />
                }
                <Link className="mt-2" to={track.path}>więcej</Link> 
                </div>
              </Popup>
            </Polyline>
          ))}
        </MapContainer>
    )
  }
  return null
}

Map.defaultProps = {
  points: [],
  tracks: [],
}

export default Map;